/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suit-diamond-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.45 7.4L7.2 1.067a1 1 0 011.6 0L13.55 7.4a1 1 0 010 1.2L8.8 14.933a1 1 0 01-1.6 0L2.45 8.6a1 1 0 010-1.2"/>',
    },
});
